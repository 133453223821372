import * as React from 'react';

import Layout from '../components/templates/Layout';
import Seo from '../components/parts/Seo';

const ThanksPage = () => {
  return (
    <Layout pageLocation="/thanks/">
      <Seo pageTitle="Thank you for contact" pageLocation="/thanks/" />
    </Layout>
  );
};

export default ThanksPage;
